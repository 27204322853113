import React from 'react';

export const If = ({ cond, children }) => {
    if( cond ){
        return children;
    }
    return null;
}   

export const Else = ({cond, children}) => {
    if( ! cond ){
        return children;
    }
    return null;
}

export const RenderIf = ({ cond, children }) => {
    if(!Array.isArray(children)){
        children = [children];
    }

    return children.map(child => React.cloneElement(child, {
        cond
    }));
}

export const renderIf = ( cond, ifTrue, otherwise = null ) => {
    if( cond )
        return ifTrue;
    else
        return otherwise;
}