export const update = (key, obj) => {
    let currData = fetch(key);

    const newData = {
        ...currData,
        ...obj
    }

    return localStorage.setItem(key, JSON.stringify(newData));
}

export const replace = (key, val) => {
    return localStorage.setItem(key, JSON.stringify(val));
}

export const fetch = ( key ) => {
    let item = localStorage.getItem(key);

    if( item ){
        return JSON.parse(item);
    }
    else{
        return undefined;
    }

}

export const defaults = () => {
    if( ! localStorage.getItem("currentStep") ){
        replace("currentStep", 1);
    }

    if( ! localStorage.getItem("formData") ){
        replace("formData", {});
    }
}