import React, { Component } from 'react';
import Form from '../components/Form';
import { Redirect } from 'react-router-dom';
import { fetch } from '../utilities/localStorage'
import Header from '../components/Header';

export default class Initial extends Component{
    state = {
        firstName : "",
        lastName: "",
        company: "",
        email: "",
        shouldRedirect: false,
        pageHeight: window.innerHeight - 87,
        termsAndConditions: false,
        termsError: false
    } 

    componentDidMount(){
        let localStorage = fetch("formData");

        this.setState({
            ...localStorage
        })

        this.props.setStep(1);
    }

    getFields = () => ([
        { type: "text", name: "First Name", value: this.state.firstName },
        { type: "text", name: "Last Name", value: this.state.lastName },
        { type: "text", name: "Company", value: this.state.company },
        { type: "email", name: "Email", value: this.state.email },
        { type: "text", name: "How Did You Hear About Us", value: this.state.howDidYouHearAboutUs },
    ])

    handleChange = ({ name, value }) => this.setState({
        [name] : value
    });

    handleCheckbox = (e) => this.setState({
        [e.target.name] : e.target.checked
    })

    handleSubmit = e => {
        if( !this.state.termsAndConditions ){
            return this.setState({
                termsError: true
            })
        }

        this.props.globalUpdate({
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            company: this.state.company,
            termsAndConditions: this.state.termsAndConditions,
            howDidYouHearAboutUs: this.state.howDidYouHearAboutUs
        })

        this.props.history.push("/plan/");
    }

    render(){
        return (
            <div className='initial-step'>
                <div className='form-body'>
                    <Header text="Sign Up For the InfoVera Partner Program" subhead="Step One: Basic Information"/>
                    <Form 
                        fields={this.getFields()} 
                        submitText="Next"
                        changeFn={this.handleChange} 
                        submitFn={this.handleSubmit}
                    >     
                        <div className='recyclable-form-group'>
                            <input type='checkbox' name='termsAndConditions' onChange={this.handleCheckbox} /> I Agree to the InfoVera <a href="http://partner.infovera.com/terms" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>
                            { this.state.termsError ? <p style={{color: 'red'}}>You must agree to the terms and conditions to continue.</p> : null }
                        </div>
                    </Form>
                </div>         
                   
            </div>
        )
    }
}