import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

export default class FileUpload extends Component{
    onDrop = (accepted, rejected) => {
        this.props.onDrop(accepted, rejected);
    }

    render(){
        return (
            <Dropzone onDrop={this.onDrop}>
                {({getRootProps, getInputProps, isDragActive}) => {
                    return (
                        <div
                            {...getRootProps()}
                            className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                        >
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                            <p>Drop files here...</p> :
                            <div className='btn btn-outline'>Upload Logo <i className='fas fa-cloud-upload-alt'></i></div>
                        }
                        { this.props.img ? <img src={this.props.img} alt="Uploaded Logo" /> : null}
                        </div>
                    )
                    }}          
            </Dropzone>
        )
    }
}