import React, { Component } from 'react';
import genClass from '../utilities/generateClassName';
import PropTypes from 'prop-types';
import Field from './Field';
import { If } from '../utilities/RenderIf';

export default class Form extends Component{
    genClass = genClass("recyclable-form")

    handleChange = e => {
        let name = e.target.name
                    .toLowerCase()
                    .split(" ")
                    .map((el, idx) => idx !== 0 ? el.charAt(0).toUpperCase() + el.slice(1) : el )
                    .join("");
        
        return this.props.changeFn({name, value: e.target.value});
    }

    handleSubmit = e => {
        e.preventDefault();

        return this.props.submitFn(e);
    }

    render(){
        return (
            <div className={this.genClass()}>
                <div className={this.genClass("inner")}>
                    <If cond={this.props.titleText}>
                        <h4>{this.props.titleText}</h4>
                    </If>
                    <form className={this.genClass("form")} onSubmit={this.handleSubmit}>
                        {this.props.fields.map(i => (
                            <Field 
                                {...i} 
                                wrapperClass={this.genClass("group")} 
                                onChange={ this.handleChange }
                                key={i.name}
                            /> 
                        ))}
                        {this.props.children}
                        <div className={this.genClass("group")}>
                            <If cond={!this.props.hideSubmit}>
                                <button 
                                    type='submit' 
                                    className="btn btn-primary btn-right"
                                >
                                    {this.props.submitText || "Submit"} <i className='fas fa-chevron-right' />
                                </button>                               
                            </If>                     
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

Form.propTypes = {
    fields: PropTypes.array.isRequired,
    changeFn: PropTypes.func.isRequired,
    submitFn: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    subHead: PropTypes.string,
}