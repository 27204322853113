import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import genClass from '../utilities/generateClassName';
import { fetch } from '../utilities/localStorage';
import Header from '../components/Header';
import Braintree from 'braintree-web';
import axios from 'axios';
import { RenderIf, If, Else } from '../utilities/RenderIf';

export default class Payment extends Component{
    genClass = genClass("payment");

    state = {
        shouldRedirect: false,
        pageHeight: window.innerHeight - 87,
        isDisabled: false,
        promo: "",
        errorMessage: "",
        selectedPlan: {}
    }

    async componentDidMount(){
        this.props.setStep(4);

        let { data } = await axios.get(process.env.REACT_APP_CORE_API_URL + "/affiliate/plans/list/");
        let { selectedPlan } = fetch("formData");

        this.setState({
            selectedPlan: data.find(plan => plan.id === selectedPlan)
        })

        if( selectedPlan !== "trial" ){
            Braintree.setup(process.env.REACT_APP_BRAINTREE_KEY, 'dropin', {
                container: '__braintree',
                onPaymentMethodReceived: this.paymentMethodReceived
            })
        }
    }

    paymentMethodReceived = data => {
        this.setState({
            nonce: data.nonce
        })

        this.handleSubmit();
    }

    handleSubmit = async e => {
        e.preventDefault();
        
        this.setState({
            isDisabled: true
        });

        let { data } = await axios.post(`${process.env.REACT_APP_CORE_API_URL}/affiliate/progress/finish`, {
            ...fetch("formData"),
            ...this.state,
        })

        if( data.success === true ){
            this.setState({
                isDisabled: false
            });
            
            this.props.history.push("/thank-you/");
        }
        else{
            this.setState({
                isDisabled: false,
                errorMessage: data.reason
            })
        }
    }

    render(){
        return (
            <div className={`details-step`}>
                <div className='form-body' style={{height: this.state.pageHeight + "px"}}>
                    <Header text="Sign Up For the InfoVera Partner Program" subhead="Final Step: Enter Your Payment Details" />
                    <form className={this.genClass("form")} onSubmit={this.state.selectedPlan.id === "trial" ? this.handleSubmit : () => {}}>
                        <div className="selected-plan">
                            <h4>Selected Plan</h4>
                            <div className="selected-plan-row">
                                <div>Name:</div>
                                <div>{this.state.selectedPlan.name}</div>                            
                            </div>
                            <div className="selected-plan-row">
                                <div>Price</div>
                                <div className='selected-plan-price'>{this.state.selectedPlan.price}</div>
                            </div>
                            <div className="selected-plan-row">
                                <div>Duration</div>
                                <div>{ this.state.selectedPlan.id === "trial" ? '14 Days' : '12 Months' }</div>
                            </div>
                        </div>
                        {this.state.selectedPlan.id !== "trial" && <legend>All payment information is secured by <a rel="noopener noreferrer" target="_blank" href="https://www.braintreepayments.com/features/data-security"><img alt="Braintree Logo" src="/img/braintree.png" /></a>. We do not store, collect, or in any way see your payment details. All recurring billing is handled by Braintree's secure system.</legend> }
                        <div id="__braintree"></div>
                        <If cond={this.state.errorMessage}>
                            <p className='finalize-error'>
                                { this.state.errorMessage }
                            </p>
                        </If>
                        <RenderIf cond={!this.state.isDisabled}>
                            <If>
                                <button className='btn btn-primary btn-right'>Finish <i className='fas fa-check' /></button>
                            </If>
                            <Else>
                                <button className='btn btn-primary btn-right disabled'>Working <img alt="Working..." src='/img/loader.svg' /></button>
                            </Else>
                        </RenderIf>
                    </form>                
                </div>
            </div>
        )
    }
}   