import React, { Component } from 'react';
import genClass from '../utilities/generateClassName';
import Header from '../components/Header';

export default class Thanks extends Component{
    genClass = genClass("payment");

    state = {
        pageHeight: window.innerHeight - 87,
    }

    componentDidMount(){
        localStorage.clear();
    }

    render(){
        return (
            <div className={`details-step`}>
                <div className='form-body' style={{height: this.state.pageHeight + "px"}}>
                    <Header text="Thank You!" subhead="You're finished, please follow the link below and sign in with the credentials you provided." />
                    <div className='pad'>
                        <a className='btn btn-primary btn-block' href="https://partner.infovera.com/">Continue to Portal <i className='fas fa-chevron-right' /></a>
                    </div>
                </div>
            </div>
        )
    }
}