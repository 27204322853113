import React from 'react';
import genClass from '../utilities/generateClassName';

const getClass = genClass("progress")

export default ({currentStep, maxSteps}) => {
    return (
        <div className={getClass()}>
            <div className={getClass("bar")}>
                <div className={getClass("inner")} style={{ width: (currentStep/maxSteps * 100) + "%" }}>
                    <div className={getClass("text")}>Step {currentStep}/{maxSteps}</div>
                </div>
            </div>
        </div>
    )        
}