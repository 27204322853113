import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import genClass from '../utilities/generateClassName';
import { fetch } from '../utilities/localStorage';
import Header from '../components/Header';
import Form from '../components/Form';
import FileUpload from './detail/FileUpload';
import axios from 'axios';

export default class Detail extends Component{
    genClass = genClass("details");

    state = {
        shouldRedirect: false
    }

    componentDidMount(){
        this.props.setStep(3);

        this.setState({...fetch("formData")})
    }

    handleChange = ({ name, value }) => this.setState({
        [name] : value
    });

    handleSubmit = e => {
        this.props.globalUpdate({
            ...this.state
        })

        this.props.history.push("/payment/");
    }

    handleFile = async (acc, rej) => {
        let formData = new FormData();
        formData.append("image", acc[0]);
        formData.append("email", fetch("formData").email)
        let response = await axios.post(process.env.REACT_APP_CORE_API_URL  + "/affiliate/progress/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        this.setState({
            logoUrl: response.data.logoUrl
        })
    }

    getFields = () => ([
        { type: "text", name: "Address One", value: this.state.addressOne },
        { type: "text", name: "Address Two", value: this.state.addressTwo },
        { type: "text", name: "City", value: this.state.city },
        { type: "text", name: "State", value: this.state.state },
        { type: "number", name: "ZIP", value: this.state.zip },
        { type: "phone", name: "Phone", value: this.state.phone },
        { type: "text", name: "Website", value: this.state.website },
    ])

    getLoginFields = () => ([
        { type: "text", name: "Email", value: this.state.email, readOnly: true },
        { type: "password", name: "Password", value: this.state.password },
        { type: "password", name: "Confirm", value: this.state.confirm},
    ])

    getDetailFields = () => ([ 
        { type: "textarea", name: "Company Description", value: this.state.companyDescription},
        { type: "render", name: "Company Logo", value: this.state.companyLogo, render: () => <FileUpload img={this.state.logoUrl} onDrop={this.handleFile}/> },
    ])


    render(){
        return (
            <div className={`detail-step`}>
                <div className='form-body'>
                    <Header text="Sign Up For the InfoVera Partner Program" subhead="Step Three: Enter Your Details" />
                    <Form 
                        titleText="Address Details"
                        fields={this.getFields()} 
                        submitText="Next"
                        hideSubmit={true}
                        changeFn={this.handleChange} 
                        submitFn={this.handleSubmit}
                    />  
                    <Form 
                        titleText="Company Details"
                        fields={this.getDetailFields()} 
                        submitText="Next"
                        hideSubmit={true}
                        changeFn={this.handleChange} 
                        submitFn={this.handleSubmit}
                    />                  
                    <Form 
                        titleText="Login Details"
                        fields={this.getLoginFields()} 
                        submitText="Next"
                        changeFn={this.handleChange} 
                        submitFn={this.handleSubmit}
                    />                   
                </div>               
            </div>
        )
    }
}