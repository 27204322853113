import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import genClass from '../utilities/generateClassName';
import { RenderIf, If, Else } from '../utilities/RenderIf';
import { fetch } from '../utilities/localStorage';
import Header from '../components/Header';
import axios from 'axios';

export default class Plan extends Component{
    genClass = genClass("plans");

    state = {
        plans: [],
        selectedPlan: {
            features: []
        }
    }

    async componentDidMount(){
        let localStorage = fetch("formData");

        let { data } = await axios.get(process.env.REACT_APP_CORE_API_URL + "/affiliate/plans/list/");

        this.setState({
            selectedPlan: data.find(plan => plan.id === "trial"),
            plans: data
        })

        this.props.setStep(2);
    }

    handleSelection = code => {
        this.props.globalUpdate({
            selectedPlan : this.state.selectedPlan.id
        })

        this.props.history.push("/detail/");
    }

    renderFeature = feat => {
        return (
            <RenderIf cond={feat.has}>
                <If>
                    <div className={this.genClass("feature has")}>
                        {feat.text}
                    </div>
                </If>
                <Else>
                    <div className={this.genClass("feature not-has")}>
                        {feat.text}
                    </div>                    
                </Else>
            </RenderIf>
        )
    }

    getSelectorClassName = () => {
        let activeIndex = [].findIndex( r => r.code === this.state.selectedPlan.code );

        return this.genClass(`selector active-${activeIndex}`);
    }

    getPlanClassName = (plan, idx) => {
        return (
            this.genClass(`selector-item ${plan.id} ${plan.id === this.state.selectedPlan.id ? `active` : ''}`)
        )
    }

    renderSelector = (key, idx) => {
        let plan = this.state.plans.find(plan => plan.id === key);
        if( plan ){
            return (
                <div className={this.getPlanClassName(plan, idx)} onClick={ () => this.setState({ selectedPlan: plan }) }>
                    {plan.name}
                </div>
            )
        }

        return null;
    }

    render(){
        return (
            <div className={`plan-step ${this.state.selectedPlan.code}`}>
                <div className='form-body'>
                    <Header text="Sign Up For the InfoVera Partner Program" subhead="Step Two: Choose a Plan" />
                    <div className={this.getSelectorClassName()}>
                        { ["trial", "reseller", "silver", "gold", "platinum"].map(this.renderSelector) }
                    </div>
                    <div className={this.genClass(`selected ${this.state.selectedPlan.id}`)}>
                        <div className={this.genClass("selected-meta")}>
                            <img alt={this.state.selectedPlan.name} src={`/img/${this.state.selectedPlan.id}.png`} onClick={this.handleSelection} />
                        </div>
                        <div className={this.genClass(`selected-features ${this.state.selectedPlan.id}`)}>
                            <div className={this.genClass("selected-continue")} style={{marginTop: '100px'}}>
                                <button onClick={this.handleSelection} className={this.genClass("selected-continue-btn btn btn-primary")}>Continue With Plan <i className='fas fa-chevron-right' /></button>
                            </div>                             
                        </div>
                    </div>                
                </div>
            </div>
        )
    }
}