import React from 'react';

export default args => {
    const renderField = () => {
        if( args.type === "textarea" ){
            return (
                <textarea
                    type={args.type} 
                    name={args.name} 
                    value={args.value} 
                    onChange={args.onChange} 
                    placeholder={args.name}
                    { ...args.readOnly ? { readOnly: true } : {} }                
                >
                </textarea>
            )
        }

        if(args.type === "render"){
            return args.render();
        }

        return (
            <input 
                type={args.type} 
                name={args.name} 
                value={args.value} 
                onChange={args.onChange} 
                placeholder={args.name}
                { ...args.readOnly ? { readOnly: true } : {} }
            />
        )
    }

    return (
        <div className={args.wrapperClass} key={args.name}>
            <label htmlFor={args.name}>
                { renderField() }
            </label>        
        </div>
    )
}