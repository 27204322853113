import React, { Component } from 'react';
import './assets/sass/App.sass';
import genClass from './utilities/generateClassName';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { defaults , update, fetch } from './utilities/localStorage';
import axios from 'axios';

import Progress from './components/Progress';
import Initial from './pages/Initial';
import Plan from './pages/Plan';
import Detail from './pages/Detail'
import Payment from './pages/Payment'
import Thanks from './pages/Thanks'

class App extends Component {
  genClass = genClass("app");

  state = {
    currentStep: fetch("currentStep") || 1
  }

  componentDidMount(){
    defaults();
    this.setState({
      currentStep: this.getCurrentStep() || 1
    })
  }

  getCurrentStep = () => {
    return fetch("currentStep") || 1;
  }

  updateValues = async (obj) => {
    update("formData", obj);

    let allData = fetch("formData");
    await axios.post(`${process.env.REACT_APP_CORE_API_URL}/affiliate/progress`, allData);
  }
 
  setStep = currentStep => this.setState({
    currentStep
  })

  getGlobalProps = () => {
    return {
      globalUpdate: this.updateValues,
      setStep: this.setStep,
    }
  }

  render() {
    return (
      <div className="app">
        <header className={ this.genClass("header") }>
          <h1><a href="/"><img alt="InfoVera Affiliate Signup" src="/img/defaultLogo.png" /><span>INFOVERA</span></a></h1>
        </header>
        <div className={ this.genClass("body") }>
          <Router>
            <Switch>
              <Route exact path="/" render={ (props) =>  <Initial {...props} {...this.getGlobalProps()} />}/>
              <Route exact path="/plan/" render={ (props) =>  <Plan {...props} {...this.getGlobalProps()} />}/>
              <Route exact path="/detail/" render={ (props) =>  <Detail {...props} {...this.getGlobalProps()} />}/>
              <Route exact path="/payment/" render={ (props) =>  <Payment {...props} {...this.getGlobalProps()} />}/>
              <Route exact path="/thank-you/" render={ (props) =>  <Thanks />}/>
              
            </Switch>
          </Router>
          <Progress maxSteps={4} currentStep={this.state.currentStep} />
        </div>
      </div>
    );
  }
}

export default App;
